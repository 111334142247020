import React, {useState} from 'react'
import { CandidateProfilePageTexts as Texts } from '../../helpers/LayoutTexts';
import { useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Color } from '../../enums/Color';

interface Props {
    styles: any,
    percentage: number,
    showMyinformation?: (value: boolean) => void
}

export const LevelIndicator: React.FC<Props> = (props) => {
    const styles = props.styles;
    const navigate = useNavigate();

    const goToMyInformation = () => {
        navigate('/candidate/updateprofile');
        if (props.showMyinformation) {
                props.showMyinformation(true);
            }
    }

    return (
        <div style={styles.wrapper}>
            <div style={styles.level}>
                <CircularProgressbar strokeWidth={16} value={props.percentage} text={props.percentage + "%"} styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: 'butt',
                    pathColor: Color.Green,
                    textColor: Color.Green,
                    trailColor: Color.LightGreen
                })} />
            </div>
            <p style={styles.text}>{Texts.completeProfile}
                <br />
                <span style={styles.link} onClick={goToMyInformation}>{"COMPLETE NOW"}</span>
            </p>
        </div>

    )
}