import React, { useContext, useEffect, useState } from 'react';
import { Job } from '../../models/Job';
import { Logo } from './Logo';
import { Tag } from './Tag';
import { ReactComponent as Disable } from '../../assets/svg/disable.svg';
import { ReactComponent as CVLess } from '../../assets/svg/cv_less.svg';
import { ReactComponent as Clock } from '../../assets/svg/clock.svg';
import { ReactComponent as Bookmark } from '../../assets/svg/bookmark.svg';
import { ReactComponent as BookmarkActive } from '../../assets/svg/bookmark-active.svg';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Briefcase } from '../../assets/svg/briefcase.svg';
import { ReactComponent as Globe } from '../../assets/svg/globe.svg';
import { Share } from './Share';
import { Button } from './Buttons/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';
import { ReactComponent as Bin } from '../../assets/svg/bin.svg';
import { ReactComponent as BumpUp } from '../../assets/svg/bump-up.svg';
import { getSEOKeyword } from '../../helpers/Common';
import useSaveJob from '../../hooks/useSaveJob';
import { formatLocations } from '../../helpers/JobLocationFormat';

interface Props {
    styles?: any;
    job: Job;
    saveJob?(jobId: number, isSave: boolean): void;
    isLastItem: boolean;
    appliedJobs?: Job[];
};

export const LongJobCard: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("longJobCard"));
    const job: Job = props.job;    
    const [hover, setHover] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const {isSavedJob} = useSaveJob();
    const [isSaved, setIsSaved] = useState(isSavedJob(job.jobId));
    const location = useLocation();
    const [isJobApplied, setIsJobApplied] = useState<boolean>(false);
    
    useEffect(() => {   
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("longJobCard"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);                    
    }, [isMobile]);

    useEffect(() => {        
        setIsSaved(isSavedJob(job.jobId));
        if (props.appliedJobs) {
            const hasCandidateApplied = props.appliedJobs.some(j => j.jobId == job.jobId);
            if (hasCandidateApplied) {
                setIsJobApplied(true);
            }
            else {
                setIsJobApplied(false);
            }
        }
    }, [props.job, props.appliedJobs])

    const saveJob = (event: any) => {
        event.stopPropagation();
        props.saveJob!(job.jobId, isSaved);
        setIsSaved(!isSaved);
    }

    const onClickHandler = () => {
        const url = location.pathname + location.search;
        sessionStorage.setItem("redirectUrl", url);
        let seoKeyword = getSEOKeyword(job.jobTitle + "-" + job.organizationName);
        navigate("/jobs/view/" + job.jobId + "/" + seoKeyword);
    }

    const handleVideoTagClick = (event: any) => {
        event.preventDefault()
        event.stopPropagation();
        window.open(job.videoUrl, "_blank");
    }

    const handleDisabilityClick = (event: any) => {
        event.stopPropagation();
        navigate("/home/differentlyabledfriendlyemployment");
    }

    const handleCVLessClick = (event: any) => {
        event.stopPropagation();
        navigate("/jobs/cvless");
    }

    const onMouseEnter = () => {
        if(!isMobile) {
            setHover(true);
        }
    }

    const onMouseLeave = () => {
        if(!isMobile) {
            setHover(false);
        }
    }

    return (
        <a href={`/jobs/view/${job.jobId}/${getSEOKeyword(job.jobTitle + "-" + job.organizationName)}`} style={{ textDecoration: "none" }}>   
            <div 
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave} 
                onClick={(event) => {event.preventDefault(); onClickHandler();}}
                style={{...styles, ...(hover && styles.hover)}}
            > 
                <div>
                    <Logo style={{...styles.logo, ...(hover && styles.logo.hover)}} organizationId={job.organizationId} organizationName={job.organizationName} hasLogo={job.hasLogo} logoUri={job.logoUri} jobId={job.jobId} isGovernmentJob={job.isGovernmentJob} />
                    {/* This is loading only mobile platform */}
                    <div style={styles.topMobileTagWrapper}>
                        {job.urgent && <Tag styles={styles.urgentTag}>Urgent</Tag>}
                        {job.isVideoEnabled && <div onClick={handleVideoTagClick} style={styles.videoTag}>Video</div>}                        
                    </div>
                </div>
                <div style={{...styles.detailWrapper, ...(hover && styles.detailWrapper.hover)}}>                
                    <div style={{...styles.rowWrapper, ...styles.titleWrapper}}>
                        <div style={{...styles.title, ...(isSaved && styles.title.jobSaved)}}>
                            {job.jobTitle}                        
                        </div> 
                        {job.bumpUp && <div style={styles.bumpUp}><BumpUp style={styles.bumpUpIcon} /></div>}
                        {/* This is loading only desktop platform */}
                        <div style={styles.desktopTagWrapper}>
                            {job.urgent && <Tag styles={styles.urgentTag}>Urgent</Tag>}
                            {job.isVideoEnabled && <div onClick={handleVideoTagClick} style={styles.videoTag}>Video</div>}                        
                        </div>
                        <Button onClick={(event) => {event.preventDefault(); saveJob(event)}} style={styles.saveJobWrapper}>
                            {isSaved ? <>
                                <BookmarkActive style={styles.saveIcon} />
                                <span style={styles.jobSaved}>Saved</span>
                            </> : 
                            <Bookmark style={styles.saveIcon} />}
                        </Button>
                        {/* This is loading only desktop platform */}
                        <div style={styles.desktopCountDown}>
                            <Clock style={styles.smallIcon} />
                            {job.expireDayCountDown} {job.expireDayCountDown > 1 ? " days left" : " day left"}
                        </div>               
                    </div> 
                    <div style={{...styles.rowWrapper, ...styles.organizationWrapper}}>
                        <div style={styles.organization}>{job.organizationName}</div>
                        {job.disabilityFriendly && <Disable style={styles.disableIcon} onClick={handleDisabilityClick} />}
                        {job.isPreApplicationFormEnabled && <CVLess style={styles.cvLessIcon} onClick={handleCVLessClick} />}
                        {(!isMobile && isJobApplied) && <div style={styles.appliedText}>Already Applied</div>}
                    </div> 
                    <div style={styles.toggleWrapper}>
                        <div style={styles.rowWrapper}>
                            <div style={styles.text}>
                                <Location style={styles.locationIcon} />
                                <div style={styles.location}>
                                    {formatLocations(job.locations)}
                                </div>
                            </div>
                            {/* This is loading only mobile platform */}
                            <div style={styles.mobileCountDown}>
                                <Clock style={styles.smallIcon}/>
                                {job.expireDayCountDown} {job.expireDayCountDown > 1 ? " days left" : " day left"}
                            </div>
                        </div>
                        <div style={styles.rowWrapper}>                    
                            <div style={styles.text}>
                                <Briefcase style={styles.smallIcon} />
                                <div>{job.jobType}</div>
                            </div>
                            {job.remote && <div style={styles.text}>
                                <Globe style={styles.smallIcon} />{"Remote"}                    
                            </div>}
                            {(isMobile && isJobApplied) && <div style={styles.appliedText}>Already Applied</div>}
                        </div>
                    </div>
                    <div style={{...styles.applyWrapper, ...(hover && styles.applyWrapper.hover)}}>
                        <Button type="button" style={styles.applyButton}>{"APPLY NOW"}</Button>
                        <Button type="button" style={styles.removeButton} onClick={(event) => {event.preventDefault(); saveJob(event)}}>
                            <Bin style={styles.removeButtonIcon} />
                        </Button>
                    </div>
                    <div>
                        <Share styles={{...styles.share, ...(hover && styles.share.hover)}} jobId={job.jobId} jobTitle={job.jobTitle} organizationName={job.organizationName}/>
                    </div>
                    {/* This is loading only mobile platform */}
                    <div style={styles.bottomMobileTagWrapper}>
                        {job.urgent && <Tag styles={styles.urgentTag}>URGENT</Tag>}
                        {job.isVideoEnabled && <div onClick={handleVideoTagClick} style={styles.videoTag}>VIDEO</div>}                        
                    </div>
                </div>
                {!hover && !props.isLastItem && <hr style={styles.bottomBorder}/>}
            </div>
        </a>        
    );
}