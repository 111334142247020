import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { FeedbackCard } from "../Shared/FeedbackCard";
import { getAllSectors } from "../../services/JobService";
import { Sector } from "../../models/Sector";
import useErrorPage from "../../hooks/useErrorPage";

interface Props {
    jobCount: number;
    jobCountTitle: string;
    styles: any;
    isLoading: boolean;
    sectorIds: string;
}

export const JobsCount: React.FC<Props> = (props) => {
    let styles = props.styles;
    const [selectedSectors, setSelectedSectors] = useState<string>("");
    const [toErrorPage] = useErrorPage();

    useEffect(() => {
        getAllSectorsList();
    },[props.sectorIds])

    async function getAllSectorsList() {
        let selectedSectorIds = props.sectorIds.split(',').map(Number);
        await getAllSectors()
        .then((sectorList : Sector[]) => {
            const selectedSectors = sectorList.filter(x => selectedSectorIds.includes(x.sectorId));
            const selectedSectorNames = selectedSectors.map(x => x.sectorName).join(", ");
            setSelectedSectors(selectedSectorNames);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    return (
        <>
            {props.isLoading ? <div style={styles.loaderWrapper}>
                <ContentLoader height={"100%"} width={"100%"}>
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
            </div>
                : <>
                    {props.jobCount > 0 ?
                        <div style={styles.wrapper}>
                            <span style={styles.countText}>
                                <span style={styles.count}>{props.jobCount}</span> {selectedSectors} {props.jobCountTitle}
                            </span> 
                        </div>
                        : <div style={styles.noResults}>
                            <span style={styles.noResults.zero}>0</span> Job Results
                            <FeedbackCard styles={styles.noResults} message={"There are no any results for your searches"} />
                        </div>
                    }
                </>
            }
        </>
    );
};
