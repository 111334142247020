import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { StyleContext } from "../../providers/StyleContextProvider";
import { Banner } from "../../models/Banner";
import { getBanners, updateBannerClickCount } from "../../services/HomeService";
import { AuthContext } from "../../providers/AuthContextProvider";
import useErrorPage from "../../hooks/useErrorPage";
import { LoggedCandidate } from "../../models/LoggedCandidate";
import { Adsense } from '@ctrl/react-adsense';

interface Props {
    styles?: any;
    adsenseClient: string;
    adsenseSlotOne: string;
    adsenseSlotTwo?: string;
}

export const HorizontalBanner: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const[styles, setStyles] = useState<any>(styleContext.getComponentStyle("horizontalBanner"));  
    const[bannersOne, setBannersOne] = useState<Banner[]>([]);
    const[bannersTwo, setBannersTwo] = useState<Banner[]>([]);                
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const [toErrorPage] = useErrorPage();  
    const[loading, setLoading] = useState<boolean>(true);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
   
    useEffect(() => {                     
        setStyles(styleContext.getComponentStyle("horizontalBanner"));                                      
    }, [isMobile]);
        
    useEffect(() => {
        getBannerList();
    }, []);

    const sliderSettings = {       
        infinite: true,        
        dots: true,         
        rows: 1,
        speed: 1000,
        autoplay: true,      
        autoplaySpeed: 3000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: false,
        appendDots: (dots: any) => (
            <div style={styles.dotsContainer}>
                <ul style={styles.dots} className={"slick-dots-white"}>{dots}</ul>
            </div>
        )
    };
    
    async function getBannerList() {          
        await getBanners("v2-horizontal-banner")
        .then((bannerList: Banner[]) => {
            if(isMobile){
                setBannersOne(bannerList);
            }
            else {                    
                let bannerListOne = bannerList.filter(x => x.keyWord.includes("v2-horizontal-banner-i-"));
                setBannersOne(bannerListOne);
                let bannerListTwo = bannerList.filter(x => x.keyWord.includes("v2-horizontal-banner-ii-"));
                setBannersTwo(bannerListTwo);
            }
            setLoading(false);
        })    
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    const redirectToBannerUrl = (banner: Banner) => {        
        window.open(banner.redirectingUrl, '_blank');
        updateBannerClickCount(banner.id);
    }

    return (
        <>
            {!loading && <div style={styles.wrapper}>
                <div style={styles.sliderOne}>                
                    {bannersOne.length > 0 ? <Slider {...sliderSettings}>
                        {bannersOne.map((banner, index) =>
                            <div key={index} onClick={() => redirectToBannerUrl(banner)}>
                                <img src={banner.keyWord} style={styles.banner} alt="Web banners designed for displaying job opportunities"></img>                        
                            </div>                     
                        )}
                    </Slider> 
                    : !candidate?.isPremium && <>
                        <Adsense style={styles.googleBanner} client={props.adsenseClient} slot={props.adsenseSlotOne} />                        
                    </>}               
                </div>
                <div style={styles.sliderTwo}>
                    {bannersTwo.length > 0 ? <Slider {...sliderSettings}>
                        {bannersTwo.map((banner, index) =>
                            <div key={index} onClick={() => redirectToBannerUrl(banner)}>
                                <img src={banner.keyWord} style={styles.banner} alt="Web banners designed for displaying job opportunities"></img>                           
                            </div>                     
                        )}
                    </Slider>
                    : !candidate?.isPremium && <>
                        <Adsense style={styles.googleBanner} client={props.adsenseClient} slot={props.adsenseSlotTwo!} />
                    </>}
                </div>
            </div>}
        </>
    );
}