import { ReactComponent as CloseCircle } from '../../assets/svg/close-circle.svg';
import { ReactComponent as ZoomIn} from '../../assets/svg/zoom-in.svg';
import { ReactComponent as ZoomOut} from '../../assets/svg/zoom-out.svg';
import { ReactComponent as FullScreen} from '../../assets/svg/full-screen.svg';
import { ReactComponent as LeftArrow} from '../../assets/svg/left_arrow.svg';
import { ReactComponent as RightArrow} from '../../assets/svg/right_arrow.svg'
import { useEffect, useRef, useState } from 'react';
import { Button } from '../Shared/Buttons/Button';
import { CVFormat } from '../../models/CVFormat';

interface Props {
    styles?: any;
    checkInCart(cvFormat: CVFormat): boolean;
    addToCart(cvFormatId: number): any;
    popUpCV?: CVFormat;
    toggle(): void;
}

interface position {
    x : number;
    y : number;
}

export const CVPopUp : React.FC<Props> = (props) => {
    const styles = props.styles;
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const [zoom, setZoom] = useState<number>(1);
    const [position, setPosition] = useState<position>({x: 0, y: 0});
    const imageRef = useRef<any>(null);

    useEffect(() => {
        const image = imageRef.current;
        let isDragging = false;
        let prevPosition = { x: 0, y: 0 };
        let initialDistance: number | null = null;
        const ZOOM_IN_SCALE_FACTOR = 8;
        const ZOOM_OUT_SCALE_FACTOR = 10;
    
        const handleStart = (clientX: number, clientY: number) => {
            isDragging = true;
            prevPosition = { x: clientX, y: clientY };
        };
    
        const handleMove = (clientX: number, clientY: number) => {
            if (!isDragging) return;
            const deltaX = clientX - prevPosition.x;
            const deltaY = clientY - prevPosition.y;
            prevPosition = { x: clientX, y: clientY };
            setPosition((position) => ({
                x: position.x + deltaX,
                y: position.y + deltaY,
            }));
        };
    
        const handleEnd = () => {
            isDragging = false;
        };
    
        const handleMouseStart = (e: React.MouseEvent) => {
            e.preventDefault();
            handleStart(e.clientX, e.clientY);
        };
    
        const handleMouseMove = (e: React.MouseEvent) => {
            e.preventDefault();
            handleMove(e.clientX, e.clientY);
        };
    
        const handleMouseEnd = () => {
            handleEnd();
        };

        const getDistance = (touch1: Touch, touch2: Touch) => {
            const dx = touch2.clientX - touch1.clientX;
            const dy = touch2.clientY - touch1.clientY;
            return Math.sqrt(dx * dx + dy * dy);
        };
    
        const handleTouchStart = (e: React.TouchEvent) => {
            e.preventDefault();
        
            if (e.touches.length === 2) {
                const touch1 = e.touches[0] as unknown as Touch;;
                const touch2 = e.touches[1] as unknown as Touch;;
                initialDistance = getDistance(touch1, touch2);
            } else {
                const touch = e.touches[0];
                handleStart(touch.clientX, touch.clientY);
            }
        };
    
        const handleTouchMove = (e: React.TouchEvent) => {
            e.preventDefault();
        
            if (e.touches.length === 2 && initialDistance !== null) {
                const touch1 = e.touches[0] as unknown as Touch;;
                const touch2 = e.touches[1] as unknown as Touch;;
                const currentDistance = getDistance(touch1, touch2);
                const scaleChange = (currentDistance / initialDistance - 1);
                setZoom(prevZoom => {
                    const newZoom = prevZoom * (1 + scaleChange * (scaleChange > 0 ? ZOOM_IN_SCALE_FACTOR : ZOOM_OUT_SCALE_FACTOR));
                    return Math.max(1, newZoom);
                });
                initialDistance = currentDistance;
            } else {
                const touch = e.touches[0] as unknown as Touch;
                handleMove(touch.clientX, touch.clientY);
            }
        };
    
        const handleTouchEnd = () => {
            initialDistance = null;  
            handleEnd();
        };
    
        const handleWheel = (e: WheelEvent) => {
            e.preventDefault();
            if(e.deltaY > 0){
                handleZoomOut();
            }
            else{
                handleZoomIn();
            }        
        };
    
        image?.addEventListener("mousedown", handleMouseStart);
        image?.addEventListener("mousemove", handleMouseMove);
        image?.addEventListener("mouseup", handleMouseEnd);
    
        image?.addEventListener("touchstart", handleTouchStart);
        image?.addEventListener("touchmove", handleTouchMove);
        image?.addEventListener("touchend", handleTouchEnd);
    
        image?.addEventListener("wheel", handleWheel);
    
        return () => {
            image?.removeEventListener("mousedown", handleMouseStart);
            image?.removeEventListener("mousemove", handleMouseMove);
            image?.removeEventListener("mouseup", handleMouseEnd);
    
            image?.removeEventListener("touchstart", handleTouchStart);
            image?.removeEventListener("touchmove", handleTouchMove);
            image?.removeEventListener("touchend", handleTouchEnd);

            image?.removeEventListener("wheel", handleWheel);
        };
    }, [imageRef, zoom, setPosition]);

    const handleCVPopUpToggle = () => {                
        setZoom(1);
        setPosition({x: 0, y: 0});
        setFullscreen(false);
        props.toggle();
        document.body.style.overflow = "visible";
    }

    const handleZoomIn = () => {
        setZoom((prev) => prev + 0.2);
    }

    const handleZoomOut = () => {
        setZoom((prev) => Math.max(1, prev - 0.2));
    }

    const handleFullScreen = () => {
        setFullscreen((prev) => !prev);
        setPosition({x: 0, y: 0});
        setZoom(1);
    }

    document.body.style.overflow = 'hidden'
    
    return (
        <div style={styles}>
            <div style={styles.backgroundLayer} onClick={handleCVPopUpToggle}></div>
            <div style={{...styles.container, ...(fullscreen && styles.container.fullscreen)}}>
                <CloseCircle style={styles.closeButton} onClick={handleCVPopUpToggle}/>
                <div style={styles.toggleWrapper}>   
                    <div style={{...styles.leftWrapper, ...(fullscreen && styles.leftWrapper.fullscreen)}}>
                        <div>                     
                            <img 
                                ref={imageRef}
                                style={{...styles.image,transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`, ...(fullscreen && styles.image.fullscreen)}}
                                src={props.popUpCV!.previewUrl} alt='Sample CV format for job seekers'>
                            </img>
                            <div style={styles.imageLayer}>                            
                                <ZoomIn style={{...styles.zoomIn, ...(fullscreen && styles.zoomIn.fullscreen)}} onClick={handleZoomIn}/>
                                <ZoomOut style={{...styles.zoomOut, ...(fullscreen && styles.zoomOut.fullscreen)}} onClick={handleZoomOut}/>
                                <FullScreen style={{...styles.fullScreenImage, ...(fullscreen && styles.fullScreenImage.fullscreen)}} onClick={handleFullScreen}/>
                                <LeftArrow style={{...styles.arrowLeft, ...(fullscreen && styles.arrowLeft.fullscreen)}}/>
                                <span style={{...styles.pageCount, ...(fullscreen && styles.pageCount.fullscreen)}}>1 / {props.popUpCV?.pageCount}</span>
                                <RightArrow style={{...styles.arrowRight, ...(fullscreen && styles.arrowRight.fullscreen)}}/> 
                            </div>
                        </div>
                    </div>
                    {!fullscreen && (<div style={styles.rightWrapper}>
                        <div style={styles.name}>{props.popUpCV!.name}</div>
                        <div style={styles.detail}>
                            <p style={styles.price}>{props.popUpCV!.price} LKR</p>
                            <p style={styles.description}>{props.popUpCV!.description}</p>
                        </div>                                                                        
                        {props.checkInCart(props.popUpCV!) ? <Button style={{...styles.button, ...styles.button.disable}} disabled={true}>{"ALREADY ADDED"}</Button> :
                        <Button style={styles.button} onClick={() => props.addToCart(props.popUpCV!.cvFormatId)}>{"ADD TO CART"}</Button>}
                    </div>)}                     
                </div>    
            </div>
        </div>
    )
}