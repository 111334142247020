import React, { useState } from 'react';
import { ReactComponent as Quote } from '../../assets/svg/double-quote-right.svg';
import { ReactComponent as Star } from '../../assets/svg/star.svg';
import { Testimonial } from '../../models/Testimonial';
import { CommonTexts as Texts } from '../../helpers/LayoutTexts';

interface Props {
    styles: any;
    testimonial: Testimonial;   
}

export const TestimonialCard: React.FC<Props> = (props) => {
    const styles = props.styles;
    const testimonial = props.testimonial;
    const [hover, setHover] = useState<boolean>(false)

    const renderStars = () => {
        let stars = [];
        for (let i = 0; i < testimonial.starCount; i++) {
            stars.push(<Star key={i} style={styles.star}/>);
        }
        return stars;
    };

    return (
        <div style={styles}>  
            <div style={styles.rowWrapper}>
                <div className="testimonialScrollArea"
                  onMouseEnter={() => {setHover(true); }}
                  onMouseLeave={() => {setHover(false); }} style={{...styles.title,...(hover && styles.title.hover)}}>
                    {testimonial.title}
                </div>
                <Quote style={styles.quoteIcon}/>
            </div>            
            <div style={styles.starWrapper}>
                {renderStars()}
            </div>
            <div className="testimonialScrollArea"
              onMouseEnter={() => {setHover(true); }}
              onMouseLeave={() => {setHover(false); }} style={{...styles.description,...(hover && styles.description.hover)}}>
                {testimonial.description}
            </div>
            <div style={styles.bottomWrapper}>
                <div style={styles.userWrapper}>
                    <img style={styles.userLogo} src={testimonial.userImageUrl ? testimonial.userImageUrl : Texts.defaultUser} alt="user image"/>
                    <div>
                        <div style={styles.userName}>{testimonial.userName}</div>
                        <div style={styles.userJobTitle}>{testimonial.userJobTitle}</div>
                        <div style={styles.organization}>{testimonial.organizationName}</div>
                    </div>
                </div>
                <div style={styles.organizationLogoWrapper}>
                  {testimonial.isRecruiter && <img style={styles.organizationLogo} src={testimonial.organizationLogoUrl} alt="organization logo"/>}
                </div>
            </div>            
        </div>
    )
}