import React, { useContext, useState } from "react";
import { TextInput } from "../Shared/Inputs/TextInput";
import { SubmitArea } from "./SubmitArea";
import { JobApplyPageTexts as Texts, SubmitFormText } from '../../helpers/LayoutTexts'
import { ReactComponent as Icon } from '../../assets/svg/drop_down.svg';
import { JobApplyQuestion, JobApplyQuestionModel } from "../../models/JobQuestion";
import { DropdownItem } from "../Shared/DropdownItem";
import { AuthContext } from "../../providers/AuthContextProvider";
import { validateAddress, validateEmail, validateNIC, validateName, validatePhoneNumber } from "../../helpers/Common";
import { ErrorIndicator } from "../Shared/Inputs/ErrorIndicator";
import { InputValidation } from "../../models/InputValidation";
import { ReactComponent as Tick } from '../../assets/svg/tick_mark.svg';
import { LoggedCandidate } from "../../models/LoggedCandidate";

interface Props {
    styles: any;
    jobId: number;
    handlePreApplySubmit(jobApplyQuestionModel: JobApplyQuestionModel): Promise<any>;    
    preApplyQuestions: JobApplyQuestion[];
    openAlert(status: string, message: string): void;
}

export const PreApplyForm: React.FC<Props> = (props) => {
    const styles = props.styles;
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [hoveredQuestion, setHoveredQuestion] = useState<number>(-1);
    const [preApplyQuestions, setPreApplyQuestions] = useState<JobApplyQuestion[]>(props.preApplyQuestions);

    const handleAnswerQuestion = (questionIndex: number, questionTypeId: number, answerIndex?: number, answer?: string) => {
        let tempQuestions = [...preApplyQuestions];
        switch (questionTypeId) {
            case 3:
                const removeDuplicateOptions = Array.from(new Set(tempQuestions[questionIndex].singleChoiceAnswers!.map(answer => answer.answer)));
                tempQuestions[questionIndex].singleChoiceAnswers!.forEach((e) => { e.selected = false });
                tempQuestions[questionIndex].singleChoiceAnswers![answerIndex!].selected = true;
                tempQuestions[questionIndex].candidateAnswer = removeDuplicateOptions[answerIndex!]
                break;
            case 5:
                tempQuestions[questionIndex].openAnswer = answer!;
                tempQuestions[questionIndex].candidateAnswer = answer!;
                break;
        }
        tempQuestions[questionIndex].validation = getInputValidation(tempQuestions[questionIndex], tempQuestions[questionIndex].candidateAnswer);
        setPreApplyQuestions(tempQuestions);
        setHoveredQuestion(-1);
    }

    const handlePreApplySubmit = (event: React.FormEvent) => {
        event.preventDefault();
        let validInputCount = preApplyQuestions.filter(x => x.validation && x.validation.valid).length;
        if(validInputCount < preApplyQuestions.length) {
            validInputCount = validateForm();
        }
        if (validInputCount === preApplyQuestions.length) {
            const jobApplyQuestionModel: JobApplyQuestionModel = {
                candidateId: candidate === null ? 0 : candidate.candidateId,
                jobId: props.jobId,
                jobApplyQuestions: preApplyQuestions
            }
            props.handlePreApplySubmit(jobApplyQuestionModel);
        }
        else {
            props.openAlert("error", SubmitFormText.error);
        }
    };

    const validateForm = (): number => {
        let tempQuestions = [...preApplyQuestions];
        tempQuestions.forEach(x => { x.validation = getInputValidation(x, x.candidateAnswer); })
        setPreApplyQuestions(tempQuestions);
        const validInputCount = tempQuestions.filter(x => x.validation && x.validation.valid).length;
        return validInputCount;
    }

    const handleInputBlur = (question: JobApplyQuestion, value: string) => {
        let tempQuestions = [...preApplyQuestions];
        tempQuestions.find(x => x.questionId === question.questionId)!.validation = getInputValidation(question, value);
        setPreApplyQuestions(tempQuestions);
    }

    const getInputValidation = (question: JobApplyQuestion, value: string | null) => {
        let validation: InputValidation = { valid: true, invalid: false };
        value = value ? value.trim() : "";
        if (question.question === "Email" && value !== "") {
            if (!validateEmail(value)) {
                validation = { valid: false, invalid: true, invalidMessage: "Invalid email address!" };
            }
        }
        else if (question.question === "Name") {            
            validation = validateName("Name", value, 100);
        }
        else if (question.question === "Address") {
            if (value === "") {
                validation = { valid: false, invalid: true, invalidMessage: "Address is required" };
            }
            else if (!validateAddress(value)) {
                validation = { valid: false, invalid: true, invalidMessage: "Invalid address!" };
            } 
        }
        else if (question.question === "NIC Number") {
            if (value === "") {
                validation = { valid: false, invalid: true, invalidMessage: "NIC number is required" };
            }
            else if (!validateNIC(value)) {
                validation = { valid: false, invalid: true, invalidMessage: "Invalid NIC number!" };
            } 
        }
        else if (question.question === "Mobile Number") {
            if (value === "") {
                validation = { valid: false, invalid: true, invalidMessage: "Mobile number is required" };
            }
            else if (!validatePhoneNumber(value)) {
                validation = { valid: false, invalid: true, invalidMessage: "Invalid mobile number!" };
            }
        }
        else if (question.question === "Re-Type Mobile Number") {
            if (value === "") {
                validation = { valid: false, invalid: true, invalidMessage: "Re-Type Mobile Number is required" };
            }
            else {
                let mobileNumber = preApplyQuestions.find(question => question.question === "Mobile Number")!.candidateAnswer;
                if (mobileNumber !== value) {
                    validation = { valid: false, invalid: true, invalidMessage: "Mobile Number and Re-Type Mobile Number should be matched" }
                }
            }
        }
        else if (question.question === "Secondary Mobile Number"  && value !== "") {
            if (!validatePhoneNumber(value)) {
                validation = { valid: false, invalid: true, invalidMessage: "Invalid secondary mobile number!" };
            }
        }
        else if (question.question !== "Email" && question.question !== "Secondary Mobile Number" && value === "") {
            validation = { valid: false, invalid: true, invalidMessage: "This field is required" };
        }
        return validation;
    }

    const expandQuestionAnswers = (questionIndex: number) => {
        if(questionIndex === hoveredQuestion) {
            setHoveredQuestion(-1);
        }
        else {
            setHoveredQuestion(questionIndex);
        }
    }

    return (
        <>
            <form onSubmit={handlePreApplySubmit}>
                <h5 style={styles.applyStage}>{Texts.applyStage.apply}</h5>
                <p style={styles.stageDescription}>{Texts.stageDescription.cvLessApply}</p>
                {preApplyQuestions.length > 0 && preApplyQuestions.map((question, questionIndex) =>
                    <div key={questionIndex} style={{ ...styles.questionWrapper, ...(question.question === "Address" && styles.longQuestionWrapper) }}>
                        <p style={styles.question}>
                            {question.question === "Name" && "Full Name (First Name & Last Name only)"}
                            {question.question === "Select Preferred Interview Location" && "Select where you wish to work"}
                            {question.question !== "Name" && question.question !== "Select Preferred Interview Location" && question.question}
                            {question.question !== "Email" && question.question !== "Secondary Mobile Number" && <span> *</span>}
                        </p>
                        {question.questionTypeId === 3 &&
                            <div onClick={() => expandQuestionAnswers(questionIndex)}>
                                <div style={{ ...styles.answerWrapper, ...(hoveredQuestion === questionIndex && styles.answerWrapper.hover), ...(question.validation && (question.validation.invalid ? styles.invalid : styles.valid)) }}>
                                    <div style={styles.text}>{question.candidateAnswer ? question.candidateAnswer : "Select One"}</div>
                                    {question.validation && question.validation.invalid ? <ErrorIndicator invalidMessage={question.validation.invalidMessage!} styles={styles.inputError} /> :
                                        <Icon style={{ ...styles.icon, ...(hoveredQuestion === questionIndex && styles.rotateIcon) }} />}
                                </div>
                                <div style={hoveredQuestion === questionIndex ? styles.hover : styles.leave} className={"scrollArea"}>
                                    {Array.from(new Set(question.singleChoiceAnswers!.map(answer => answer.answer))).map((singleAnswer, answerIndex) => (
                                    <DropdownItem key={answerIndex} styles={question.question !== "Select Preferred Interview Date" ? styles.option : styles.datePicker} onClick={() => handleAnswerQuestion(questionIndex, question.questionTypeId, answerIndex)}>
                                        {singleAnswer}
                                        {question.question !== "Select Preferred Interview Date" ? <hr style={(question.singleChoiceAnswers!.length - 1 !== answerIndex) ? styles.bottomBorder : styles.bottomBorder.hide} /> : <Tick style={styles.tickMark} />}
                                    </DropdownItem>
                                    ))}
                                </div>
                            </div>
                        }
                        {question.questionTypeId === 5 &&
                            <TextInput 
                                styles={styles.textInput} 
                                inputType={"text"} 
                                name={question.question} 
                                value={question.openAnswer} 
                                handleBlur={(e) => handleInputBlur(question, e!.target.value)}                                
                                handleChange={(e) => handleAnswerQuestion(questionIndex, question.questionTypeId, 0, e!.target.value)} 
                                validation={question.validation} 
                            />
                        }
                    </div>
                )}
            </form>
            <SubmitArea styles={styles.submitArea} submitNotice={Texts.cvLessApplyNotice} submitButtonText={Texts.submitButton} draftEnable={false} handleSubmit={handlePreApplySubmit} />            
        </>
    );
};
