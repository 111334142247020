import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Footer } from "../components/Shared/Footer";
import { CandidatePremiumPageTexts as Texts, PaymentIcons } from '../helpers/LayoutTexts';
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthContextProvider";
import { Button } from "../components/Shared/Buttons/Button";
import { ReactComponent as Crown } from '../assets/svg/crown.svg';
import { PrivilegeCard } from "../components/CandidatePremium/PrivilegeCard";
import { CheckoutTypes } from "../enums/CheckoutTypes";
import { Helmet } from "react-helmet-async";
import { LoggedCandidate } from "../models/LoggedCandidate";

export const CandidatePremiumPage: React.FC = () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("candidatePremiumPage"))               
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const location = useLocation();    
    
    useEffect(() => {                        
        if(candidate === null) {  
            const searchParams = { callbackUrl: "/candidate/premium" };
            navigate({
                pathname: '/candidates/login',
                search: `?${createSearchParams(searchParams)}`
            });
        } 
    }, []);

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("candidatePremiumPage"));    
    }, [isMobile]);

    const toCheckout = () => {
        navigate('/candidateCheckout', {
            state: {                
                checkoutType: CheckoutTypes.PremierAccounts,                
                price: 1000,
                previousUrl: location.pathname + location.search
            }
        })
    }

    return (
        <>   
            <Helmet><title>Premium Candidate - XpressJobs</title></Helmet>
            <Header styleSheet="lowHeader" searchEnable={false}/>
            <div style={styles.premiumCard}>
                <div style={styles.premium}>
                    <Crown style={styles.premiumIcon}/>{"Premium"}
                </div>
                <div style={styles.toggleWrapper}>
                    <div style={styles.makePremium}>{Texts.makePremium}</div>
                    <div style={styles.priceWrapper}>
                        <div style={styles.price}>{"Only 1000"}<span style={styles.price.lkr}>{"LKR"}</span></div>
                        <div style={styles.perYear}>{"Per year"}</div>
                        <Button style={styles.buyNowButton} onClick={toCheckout}>{"BUY NOW"}</Button>
                    </div>
                </div>
            </div> 
            <div style={styles.description}>{Texts.description}</div>   
            <div style={{...styles.toggleWrapper, ...styles.privilegeWrapper}}>
                <PrivilegeCard styles={styles.privilegeCard} text={Texts.makeTop}/>
                <PrivilegeCard styles={styles.privilegeCard} text={Texts.highlightApplication}/>
                <PrivilegeCard styles={styles.privilegeCard} text={Texts.adsFree}/>
                <PrivilegeCard styles={styles.privilegeCard} text={Texts.bioData}/>
            </div>
            <iframe style={styles.video} src={"https://www.youtube.com/embed/JWhWNONXdFo?showinfo=0"} frame-border="0" data-allow="autoplay; encrypted-media" allowFullScreen></iframe>
            <div style={styles.buyNowWrapper}>
                <Button style={styles.buyNowButton} onClick={toCheckout}>{"BUY NOW"}</Button>
                <div style={styles.iconGride}>
                    {PaymentIcons.map((paymentIcon, index) =>                    
                        <img key={index} src={paymentIcon} style={styles.paymentIcon} alt="payment icon"/>                    
                    )}
                </div>
            </div>            
            <Footer/>
        </>
    );
}