import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Footer } from "../components/Shared/Footer";
import { GetInTouch } from "../components/Shared/GetInTouch";
import { PreferredPartnersTexts as Texts } from '../helpers/LayoutTexts';
import Slider from "react-slick";
import { getPartners } from "../services/HomeService";
import { Partner } from '../models/Partner';
import useErrorPage from "../hooks/useErrorPage";
import { PartnersLoader } from "../components/Loaders/PartnersLoader";

export const PreferredPartnersPage: React.FC = () => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("preferredPartnersPage"))  ;
    const [partners,setPartners] = useState<Partner[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [toErrorPage] = useErrorPage();                
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    


    useEffect(() => {
        setStyles(styleContext.getComponentStyle("preferredPartnersPage"));    
    }, [isMobile]);

    useEffect(() => {
        getPartnersList(); 
    }, [])

    async function getPartnersList() {
        setLoading(true);
        await getPartners()
        .then((partnerList: Partner[]) => {
           setPartners(partnerList);
           setLoading(false)
        })
        .catch((error) => {
            toErrorPage(error)
        })
    }
    
    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0%",
        dots: true,                 
        speed: 500,
        autoplay: true,      
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesPerRow: 1
    };

    const readArticle = (articleUrl: string) => {
        window.open(articleUrl, '_blank');
    }

    return (
        <>
            <Header styleSheet="lowHeader" searchEnable={false} heading={"Preferred Partners"} text={isMobile ? undefined : Texts.text}/> 
            <p style={styles.text}>{Texts.text}</p> 
            {loading ? <PartnersLoader/> : <div style={styles.wrapper}>
                {isMobile ? <div style={styles.logoWrapper}>
                    <Slider {...sliderSettings}>            
                        {partners.map((partner,index) => 
                            <div key={index} onClick={() => readArticle(partner.pressReleaseUrl)}>
                                <img src={partner.logoUrl} style={styles.logo} alt="partner logo"/>
                            </div>                    
                        )}                 
                    </Slider> 
                </div>                 
                : <div style={styles.container}>                                                
                    {partners.map((partner,index) =>                    
                        <img key={index} src={partner.logoUrl} alt="partner logo" style={styles.logo} onClick={() => readArticle(partner.pressReleaseUrl)}/>                    
                    )}                                         
                </div>}
            </div>}                
            <GetInTouch/>
            <Footer/>
        </>
    );
}