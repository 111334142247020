import React, { useContext, useEffect, useState, useRef } from 'react';
import { ShortJobCardList } from '../components/Shared/ShortJobCardList';
import { Footer } from '../components/Shared/Footer';
import { Header } from '../components/Shared/Header';
import { getCandidateRecommendedJobs, getCandidateAppliedJobs } from '../services/JobService';
import { Statistics } from '../components/Shared/Statistics';
import { JobCategories } from '../components/Home/JobCategories';
import { TopHiringOrganizations } from '../components/Home/TopHiringOrganizations';
import { LogoGride } from '../components/Shared/LogoGride';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { StyleContext } from '../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { Button } from '../components/Shared/Buttons/Button';
import { CommonTexts, CandidateHomeTexts as Texts, HomeTexts as HeaderTexts } from '../helpers/LayoutTexts';
import { VerticalBanner } from '../components/Shared/VerticalBanner';
import { SelectInputs } from '../models/SelectInputs';
import { AuthContext } from '../providers/AuthContextProvider';
import { CandidateSavedJobs } from '../components/Shared/CandidateSavedJobs';
import { getFeaturedOrganizations } from '../services/HomeService';
import { Helmet } from 'react-helmet-async';
import { LoggedCandidate } from '../models/LoggedCandidate';
import { ReactComponent as CloseCircle } from '../assets/svg/close-circle.svg';
import { getSEOKeyword } from '../helpers/Common';
import { CandidateApplication } from '../models/CandidateApplication';
import { ApplicationCard } from '../components/CandidateProfile/ApplicationCard';
import { FeedbackCard } from '../components/Shared/FeedbackCard';
import { ShortJobCardListLoader } from '../components/Loaders/ShortJobCardListLoader';
import Slider from 'react-slick';
import { ReactComponent as RightArrow } from '../assets/svg/right_arrow.svg';

const sortBySelectInputs: SelectInputs = {
    default: 'Default',
    options: [
        { "value": "ExpiryDateOnWebsite", "option": "Default"},
        { "value": "ExpiryDateOnWebsite DESC", "option": "Recent"},
        // { "value": "ViewCount DESC", "option": "Trending"} // This feature will be implemented in future release
    ]
}

export const CandidateHomePage: React.FC = () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("candidateHomePage"));    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [hasAppliedJobs, setHasAppliedJobs] = useState(false);    
    const [hasSavedJobs, setHasSavedJobs] = useState(true);
    const [openVerifyDialog, setOpenVerifyDialog] = useState<boolean>(() => {
        const verificationShown = localStorage.getItem('verificationShown');
        return !verificationShown;
    });
    const recommendedJobCount: number = isMobile ? 10 : 24;     
    const appliedJobCount: number = isMobile ? 6: 4;     
    const featuredOrganizationCount: number = isMobile ? 15 : 32;    
    const [jobApplications, setJobApplications] = useState([]);
    const[loading, setLoading] = useState<boolean>(true);
    const sliderRef = useRef<Slider | null>(null);

    useEffect(() => {                    
        if(candidate === null) {  
            const params = { callbackUrl: "/candidate/home" };
            navigate({
                pathname: '/candidates/login',
                search: `?${createSearchParams(params)}`
            });
        }
        getAppliedJobs(); 
    }, []);

    useEffect(() => {            
        if (!candidate?.accountVerified && openVerifyDialog) {
            localStorage.setItem('verificationShown', 'true');
        }
    }, [candidate, openVerifyDialog]);

    useEffect(() => {            
        setStyles(styleContext.getComponentStyle("candidateHomePage"));
    }, [isMobile]);

    const toMyApplications = () => {
        navigate('/candidate/myapplications');
    }

    const handleOpenVerifyDialog = () => {
        setOpenVerifyDialog(prev => !prev);
    }

    async function getRecommendedJobs(sortBy: string) {
        const preferredSectorIds = candidate!.preferredSectorIds.toString();
        return getCandidateRecommendedJobs(candidate!.candidateId, preferredSectorIds, recommendedJobCount, sortBy, candidate!.token);
    }

    async function getAppliedJobs() {
        const appliedJobs = await getCandidateAppliedJobs(candidate!.candidateId, appliedJobCount, candidate!.token);
        if (appliedJobs.length > 0) {
            setHasAppliedJobs(true);
        }
        setJobApplications(appliedJobs);
        setLoading(false);
    }

    const onCardClick = (application: CandidateApplication) => {        
        let seoKeyword = getSEOKeyword(application.jobTitle + "-" + application.organizationName);
        navigate("/jobs/view/" + application.jobId + "/" + seoKeyword + "?applyEnable=false");        
    }

    const sliderSettings = {
        className: "center",
        infinite: true,
        centerPadding: "0%",
        dots: true,         
        rows: 2,
        speed: isMobile ? 500 : 1000,
        autoplay: isMobile ? false : true,
        autoplaySpeed: 8000,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        slidesPerRow: isMobile ? 1 : 4,
        swipeToSlide: true,
        touchThreshold: 100,
        useTransform: false,
        appendDots: (dots: any) => (
            <div style={styles.dotsWrapper}>
                <ul style={styles.dots}>{dots}</ul>
            </div>
        )
    };
    
    return (
        <div>
            <Helmet><title>{CommonTexts.siteTitle}</title></Helmet>
            <Header styleSheet="fullHeader" searchEnable={true} searchSelectEnable={true} heading={HeaderTexts.heading} text={isMobile ? HeaderTexts.mobileText : HeaderTexts.desktopText} searchText={isMobile ? HeaderTexts.mobileSearch : HeaderTexts.desktopSearch}/>            
            {candidate && <ShortJobCardList styles={styles.recommendedJobs} title={Texts.recommendedJobs} sortEnabled={true} initialSortBy={"ExpiryDateOnWebsite"} sortSelectInputs={sortBySelectInputs} loadJobs={getRecommendedJobs}/>}
            <div style={styles.flippingWrapper}>
                <JobCategories styles={styles.jobCategories} />
                {candidate && <div style={styles.appliedJobs}>                    
                    <div style={styles.appliedJobs.titleWrapper}>
                        <span style={styles.appliedJobs.title}>{Texts.appliedJobs}</span>
                        <Button style={styles.appliedJobs.redirectButton} onClick={toMyApplications}>{"View all"}<RightArrow style={styles.appliedJobs.redirectButton.icon} /></Button>
                    </div>
                    {loading ? <ShortJobCardListLoader/> : 
                    jobApplications.length > 0 ? (!isMobile ? <div style={styles.appliedJobs.grid}>
                        {jobApplications.map((jobApplication, index) =>
                            <ApplicationCard key={index} styles={styles.application} application={jobApplication} onClick={onCardClick}/>                     
                        )}
                    </div>
                    : <div style={styles.appliedJobs.slider}>
                        <Slider ref={sliderRef} {...sliderSettings}>
                            {jobApplications.map((jobApplication, index) =>
                                <ApplicationCard key={index} styles={styles.application} application={jobApplication} onClick={onCardClick}/>                     
                            )}
                        </Slider>
                    </div>) : <FeedbackCard message={Texts.noAppliedJobs} styles={styles.noResults}/>}
                    {hasAppliedJobs && <Button style={styles.appliedJobsSeeAll} onClick={toMyApplications}>{Texts.viewAllUpper}</Button>}
                </div>}
            </div>
            <div style={styles.baseWrapper}>
                <div style={{...styles.leftWrapper, ...(!hasSavedJobs && styles.noSavedJobsWrapper)}}>
                    {candidate && <CandidateSavedJobs candidate={candidate} title={"Saved Job Posts"} viewAllEnable={true} setHasJobs={setHasSavedJobs} />}
                </div>
                {hasSavedJobs && <div style={styles.rightWrapper}>
                    <VerticalBanner adsenseClient={Texts.adClient} adsenseSlotOne={Texts.verticalAdSlot} />
                </div>}
            </div>
            <TopHiringOrganizations />
            {(!candidate?.accountVerified && openVerifyDialog) && <div style={styles.verifyDialog}>
                <div style={styles.verifyDialog.background} onClick={handleOpenVerifyDialog}></div>
                <div style={styles.verifyDialog.container}>
                    <CloseCircle style={styles.verifyDialog.close} onClick={handleOpenVerifyDialog}/>
                    <div style={styles.verifyDialog.text}>{Texts.verifyEmail}</div>
                </div>
            </div>} 
            <Statistics/>
            <LogoGride rowsCount={ isMobile ? 3 : 2 } loadOrganizations={getFeaturedOrganizations} recordsCount={featuredOrganizationCount} />
            <Footer/>
        </div>
    );
}