import React, { useRef } from 'react';
import Slider from 'react-slick';
import { ReactComponent as LeftArrow } from '../../assets/svg/left_arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/svg/right_arrow.svg';
import { Testimonial } from '../../models/Testimonial';
import { TestimonialCard } from './TestimonialCard';
import { Button } from '../Shared/Buttons/Button';

export interface Props {
    styles: any;
    title: string;
    testimonials: Testimonial[];
};

export const TestimonialSlider: React.FC<Props> = (props) => {
    const sliderRef = useRef<Slider | null>(null);    
    const styles = props.styles;

    const nextSlide = () => {
        sliderRef.current?.slickNext();
    }

    const previousSlide = () => {
        sliderRef.current?.slickPrev();
    };

    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0%",
        dots: true,         
        rows: 1,
        speed: 1000,
        autoplay: true,      
        autoplaySpeed: 8000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        useTransform: false,
        appendDots: (dots: any) => (
            <div>
                <ul style={styles.dots}>{dots}</ul>
            </div>
        )
    };

    return (
        <div style={styles}>
            <div style={styles.title}>{props.title}</div>                       
            <> 
                <Button style={{...styles.sliderButton, ...styles.sliderButton.left}} onClick={previousSlide}><LeftArrow style={styles.buttonIcon} /></Button>                
                <Slider ref={sliderRef} {...sliderSettings}>
                    {props.testimonials.map((testimonial, index) =>
                        <TestimonialCard key={index} styles={styles.card} testimonial={testimonial}/>                
                    )}
                </Slider>
                <Button style={{...styles.sliderButton, ...styles.sliderButton.right}} onClick={nextSlide}><RightArrow style={styles.buttonIcon} /></Button>
            </> 
            <img style={styles.image_1} src={process.env.PUBLIC_URL + "/img/testimonial_1.png"} alt="testimonial image"/>
        </div>
    );
}