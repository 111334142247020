import { CVFormat } from "../../models/CVFormat";
import { Button } from "../Shared/Buttons/Button";
import { ReactComponent as Bin } from '../../assets/svg/bin.svg';
import { ReactComponent as EmptyCart } from '../../assets/svg/empty-cart.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { CheckoutTypes } from "../../enums/CheckoutTypes";
import { PaymentIcons } from '../../helpers/LayoutTexts';

interface Props {
    styles: any;
    cartItems: CVFormat[];
    price: number;
    removeFromCart(cvFormatId: number): void;    
}

export const Cart: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styles = props.styles;
    const location = useLocation();

    const toCheckout = () => {
        navigate('/candidateCheckout', {
            state: {
                checkoutType: CheckoutTypes.CVFormats,
                cvList: props.cartItems,
                price: props.price,
                previousUrl: location.pathname + location.search
            }
        })
    }

    return (
        <>
            <div style={styles}>
                <h1 style={styles.heading}>{"Cart"}</h1>
                {props.cartItems.length > 0 ? <>
                    <div style={styles.cartItemsContainer}>
                    {props.cartItems.map((cartItem, index) => 
                        <div key={index} style={styles.itemWrapper}>                                                 
                            <img style={styles.image} src={cartItem.previewUrl} alt="Sample CV format preview for job seekers"></img>
                            <div>
                                <p style={styles.name}>{cartItem.name}</p>
                                <p style={styles.description}>{cartItem.description}</p>
                            </div>
                            <div style={styles.cvPrice}>{cartItem.price} LKR</div>
                            <Bin style={styles.closeIcon} onClick={() => props.removeFromCart(cartItem.cvFormatId)}/>
                        </div>
                    )}
                    </div>  
                    <hr style={styles.line}/>                
                    <p style={styles.total}>Total <span style={styles.total.price}>{props.price} LKR</span></p>
                    <Button style={styles.checkoutButton} onClick={toCheckout}>{"PROCEED TO CHECKOUT"}</Button> 
                </>                           
                : <EmptyCart style={styles.emptyCart}/>}
            </div>
            <div style={props.cartItems.length > 0 ? styles.iconGride : styles.emptyCartIconGride}>
                {PaymentIcons.map((paymentIcon, index) =>                    
                    <img key={index} src={paymentIcon} style={styles.paymentIcon} alt="payment icon"/>                    
                )}
            </div>
        </>
    );
}