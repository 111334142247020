import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { CVFormat } from "../models/CVFormat";
import { StyleContext } from "../providers/StyleContextProvider";
import { getAllCVFormats, getCVFormats } from "../services/CandidateService";
import { CVFormatsPageTexts as Texts} from "../helpers/LayoutTexts";
import { SingleSelect } from "../components/Shared/SingleSelect";
import { SelectInputs } from "../models/SelectInputs";
import { Button } from "../components/Shared/Buttons/Button";
import { Pagination } from "../components/Shared/Pagination";
import { Footer } from "../components/Shared/Footer";
import { GetInTouch } from "../components/Shared/GetInTouch";
import { Cart } from "../components/CVFormats/Cart";
import { ReactComponent as CartIcon } from "../assets/svg/cart.svg";
import { SideDrawer } from "../components/Shared/SideDrawer";
import { ReactComponent as Close } from '../assets/svg/close.svg';
import useErrorPage from "../hooks/useErrorPage";
import { CVFormatLoader } from "../components/Loaders/CVFormatLoader";
import { Helmet } from "react-helmet-async";
import { CVPopUp } from "../components/CVFormats/CVPopUp";
import { useNavigate, useSearchParams } from "react-router-dom";

export const CVFormatsPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("cvFormatsPage"));
    const [cvFormats, setCVFormats] = useState<CVFormat[]>([]);
    const [allCVFormats, setAllCVFormats] = useState<CVFormat[]>([]);
    const [cartItems, setCartItems] = useState<CVFormat[]>([]);
    const [popUpCV, setPopUpCV] = useState<CVFormat>();
    const [cartPrice, setCartPrice] = useState<number>(0);
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const cvFormatIds: string = searchParams.get("cvFormatIds") === null ? "" : searchParams.get("cvFormatIds")!;
    const pageCount: number = searchParams.get("pageCount") === null ? 1 : +searchParams.get("pageCount")!;  
    const [totalPages, setTotalPages] = useState<number>();
    const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
    const [cvPopUpOpen, setCVPopUpOpen] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [loading, setLoading] = useState<boolean>(true);
    const [toErrorPage] = useErrorPage();
    const pageSize = 20;
    const scrollElementRef = useRef<any>();

    const perPageSelectInputs: SelectInputs = {
        default: "1 Page",
        options: [            
            { "value": 1, "option": "1 Page"},
            { "value": 2, "option": "2 Pages"},
            { "value": 3, "option": "3 Pages"},
            { "value": -1, "option": "All Pages"}
        ]
    }

    useEffect(() => {
        getCVFormatList();
    }, [page, pageCount]);

    useEffect(() => {
        if(allCVFormats.length > 0) {
            prepareCart(allCVFormats); 
        }        
    }, [cvFormatIds]);

    useEffect(() => {
        getAllCVFormatList();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        setStyles(styleContext.getComponentStyle("cvFormatsPage"));    
    }, [isMobile]);

    async function getCVFormatList() {
        setLoading(true);
        await getCVFormats(page, pageSize, pageCount)
        .then((cvFormatList: CVFormat[]) => {
            setCVFormats(cvFormatList);  
            if(cvFormatList.length > 0) {
                let totalPageCount = Math.ceil(cvFormatList[0].recordCount / pageSize);            
                setTotalPages(totalPageCount);
            }
            setLoading(false);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }
    
    async function getAllCVFormatList() {
        await getAllCVFormats()
        .then((cvFormatList: CVFormat[]) => {
            setAllCVFormats(cvFormatList);
            prepareCart(cvFormatList);
        })
        .catch((error) => {
            toErrorPage(error);
        })
    }

    const prepareCart = (allCVFormats: CVFormat[]) => {
        let cvFormatIdArray: string[] = [];
        if(cvFormatIds !== "") {
            cvFormatIdArray = cvFormatIds.split(',');
        }
        let selectedCVFormats: CVFormat[] = allCVFormats.filter(x => cvFormatIdArray.includes(x.cvFormatId.toString()));
        setCartItems(selectedCVFormats);
        let totalPrice = selectedCVFormats.reduce((x, cvFormat) => x + cvFormat.price, 0);
        setCartPrice(totalPrice);
    }    

    const handlePageCountChange = (pageCount: number) => {
        searchParams.set("page", "1");
        searchParams.set("pageCount", pageCount.toString());
        const newUrl = `?${searchParams}`;
        navigate(newUrl);
    }
    
    const handlePagination = (page: number) => {
        searchParams.set("page", page.toString());
        const newUrl = `?${searchParams}`;
        scrollElementRef.current.scrollIntoView();
        navigate(newUrl);
    }

    const handleCVClick = (cvFormat: CVFormat) => {
        setPopUpCV(cvFormat);
        setCVPopUpOpen(true);
    }

    const modifyCart = (cvFormatId: number) => {
        let cvFormatIdArray: string[] = [];
        if(cvFormatIds !== "") {
            cvFormatIdArray = cvFormatIds.split(',');
        }
        let idIndex = cvFormatIdArray.indexOf(cvFormatId.toString());
        if(idIndex === -1) {        
            cvFormatIdArray.push(cvFormatId.toString());
            searchParams.set("cvFormatIds", cvFormatIdArray.toString());
        }
        else {
            cvFormatIdArray.splice(idIndex, 1);
            if(cvFormatIdArray.length === 0) {
                searchParams.delete("cvFormatIds");
            }
            else {
                searchParams.set("cvFormatIds", cvFormatIdArray.toString());
            }             
        }
        const newUrl = `?${searchParams}`;        
        navigate(newUrl); 
    }

    const checkInCart = (cvFormat: CVFormat) : boolean => {
        return cartItems.some(x => x.cvFormatId === cvFormat.cvFormatId);
    }

    const handleCartDrawerToggle = () => {        
        setCartDrawerOpen((prev) => !prev);
    }

    const handleCVPopUpToggle = () => {        
        setCVPopUpOpen((prev) => !prev);
    }

    return (
        <>
            <Helmet><title>{"Buy CV Formats - XpressJobs"}</title></Helmet>
            <Header styleSheet="lowHeader" searchEnable={false} heading={"Buy CV Formats"} text={isMobile ? undefined : Texts.text}/>                       
            <div style={styles.container}>
                <p style={styles.text}>{Texts.text}</p>
                <div style={styles.toggleWrapper}>
                    <div style={styles.leftWrapper} ref={scrollElementRef}>
                        <div style={styles.toggleWrapper}>
                            <h1 style={styles.heading}>{"Xpress CV Store"}</h1>                
                            <SingleSelect styles={styles.select} label={"Sort By"} selectInputs={perPageSelectInputs} getSelected={handlePageCountChange}/>
                        </div>
                        {loading ? <CVFormatLoader/> : <>
                            <div style={styles.grid}>
                                {cvFormats.map((cvFormat, index) => 
                                    <div key={index}>
                                        <div style={styles.cvWrapper} onClick={() => handleCVClick(cvFormat)}>                                
                                            <img style={styles.image} src={cvFormat.previewUrl} alt="Sample CV format preview for job seekers"></img>
                                            <div style={styles.layer}>
                                                <div style={styles.detailWrapper}>
                                                    <p style={styles.price}>{cvFormat.price} LKR</p>
                                                    <p style={styles.name}>{cvFormat.name}</p>
                                                    <p style={styles.description}>{cvFormat.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {checkInCart(cvFormat) ? <Button style={{...styles.button, ...styles.button.disable}} disabled={true}>{"ALREADY ADDED"}</Button> :
                                        <Button style={styles.button} onClick={() => modifyCart(cvFormat.cvFormatId)}>{"ADD TO CART"}</Button>}
                                    </div>
                                )}
                            </div>
                            {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination} extendedStyles={styles.pagination}/>}
                            <div style={styles.cartWrapper}>
                              <Button style={styles.cartButton} onClick={handleCartDrawerToggle}><CartIcon style={styles.cartButton.cartIcon}/><span style={styles.cartBadge}>{cartItems.length}</span></Button>
                            </div>
                        </>}
                    </div>
                    {isMobile && <div style={styles.mobileCartWrapper}>
                        <Cart styles={{...styles.cart, ...styles.mobileCart}} cartItems={cartItems} price={cartPrice} removeFromCart={modifyCart}/>
                    </div>}
                    <div style={styles.rightWrapper}>
                        <Cart styles={styles.cart} cartItems={cartItems} price={cartPrice} removeFromCart={modifyCart} />
                    </div>
                </div>
            </div>
            <SideDrawer styles={styles.sideDrawer} open={cartDrawerOpen} toggle={handleCartDrawerToggle}>                                
                <Close style={styles.drawerClose} onClick={handleCartDrawerToggle}/>                    
                <Cart styles={styles.cart} cartItems={cartItems} price={cartPrice} removeFromCart={modifyCart}/>                
            </SideDrawer>            
            {cvPopUpOpen && <CVPopUp styles={styles.cvPopUp} addToCart={modifyCart} checkInCart={checkInCart} popUpCV={popUpCV} toggle={handleCVPopUpToggle}/>}
            <GetInTouch/>
            <Footer/>
        </>
    );
}