import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../Shared/Buttons/Button';
import { CandidateProfilePageTexts as Texts } from '../../helpers/LayoutTexts';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';
import { ReactComponent as Crown } from '../../assets/svg/crown.svg';
import { ReactComponent as CameraIcon } from '../../assets/svg/camera.svg';
import { ReactComponent as DropDownIcon } from '../../assets/svg/drop_down.svg';
import { Switch } from '../Shared/Buttons/Switch';
import { useMediaQuery } from 'react-responsive';
import { Candidate } from '../../models/Candidate';
import { LevelIndicator } from './LevelIndicator';
import { useNavigate } from 'react-router-dom';
import { getCandidateCVURL, getCandidateProfilePictureURL, getFormatedDate, validateImageFile } from '../../helpers/Common';
import { CVPreviewer } from './CVPreviewer';
import Dropzone from 'react-dropzone';
import { ReactComponent as FileUpload } from '../../assets/svg/file-upload.svg';
import { Transition } from '../Shared/Transition';
import { PopUpWindow } from '../Shared/PopUpWindow';
import { addProfilePicture } from '../../services/CandidateService';
import { ResponseModel } from '../../models/ResponseModel';
import { StoreButton } from '../Shared/StoreButton';
import { ReactComponent as ExternalLink } from '../../assets/svg/external-link.svg';
import { DocumentViewer } from '../Shared/DocumentViewer';
import { AuthContext } from '../../providers/AuthContextProvider';
import { LoggedCandidate } from '../../models/LoggedCandidate';

interface Props {
    styles: any;
    candidate: Candidate; 
    completedPercentage: number;  
    cvFile?: string | File;  
    handleCVFileChange(files: File[]): void;
    openAlert(status: string, message: string): void;
    showMyinformation?: (value: boolean) => void    
}

export const PersonalDetails: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [cvExpand, setCVExpand] = useState<boolean>(false); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const styles = props.styles;
    const candidate = props.candidate;
    const [loading, setLoading] = useState(false);
    const candidateProfilePictureURL = getCandidateProfilePictureURL(candidate.candidateId);
    const [shareCVWithEmployers, setShareCVWithEmployers] = useState(false);
    const [uploadedProfilePicture, setUploadedProfilePicture] = useState<File>();
    const [profilePictureURL, setProfilePictureURL] = useState<string>(candidateProfilePictureURL);
    const summaryLimit: number = isMobile ? 146 : 320;
    const [isFullSummary, setIsFullSummary] = useState<boolean>(true);
    const [openCVPopup, setOpenCVPopup] = useState<boolean>(false);
    const [premiumMessage, setPremiumMessage] = useState<string>();
    const [displayPremiumMessage, setDisplayPremiumMessage] = useState<boolean>(false);
    const authContext = useContext(AuthContext);
    const loggedCandidate: LoggedCandidate | null = authContext.getLoggedCandidate();

    useEffect(() => {
        if(candidate.personalSummary !== null) {
            setIsFullSummary(candidate.personalSummary.length < summaryLimit);
        }
        if(candidate.isPremium) {
            let message = "";
            if (candidate.premiumRemainingDays > 1)
            {
                message = "You are a premier candidate. Your subscription will end up in " + candidate.premiumRemainingDays + " days";
            }
            else
            {
                message = "You are a premier candidate. Your subscription will end up in " + candidate.premiumRemainingDays + " day";
            }
            setPremiumMessage(message);
        }
    }, [])

    const handleSettingsClick = () => {
        navigate('/candidateSettings/password');
    }

    const tryPremium = () => {
        navigate({ pathname: '/candidate/premium' });
    }

    const openProfilePictureFileSelect = () => {
        document.getElementById("profile-picture")?.click();
    }

    const handleShowMyInformation = () => {
        if (props.showMyinformation) {
            props.showMyinformation(true);
        }
    };

    const handleProfilePictureFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedFile = event.currentTarget?.files;
        if(selectedFile && selectedFile.length > 0){
            let validation = validateImageFile(selectedFile[0]);
            if(validation.valid){
                let pictureURL = URL.createObjectURL(selectedFile[0]);
                setUploadedProfilePicture(selectedFile[0]);
                setProfilePictureURL(pictureURL);
            }
            else{
                props.openAlert("error", validation.invalidMessage!)
            }
            event.target.value = "";
        }
    }

    const removeSelectedProfilePicture = () => {
        setUploadedProfilePicture(undefined);
        setProfilePictureURL(candidateProfilePictureURL);
    }

    async function handleAddProfilePicture() {
        setLoading(true);
        let formData = new FormData();
        formData.append("candidateId", candidate.candidateId.toString()!);
        formData.append("profilePicture", uploadedProfilePicture!);
        await addProfilePicture(formData,loggedCandidate!.token)
        .then((response: ResponseModel) => {
            props.openAlert(response.status ? "success" : "error", response.message);
        })
        .catch(() => {
            props.openAlert("error", "There was an error during the process!")
            setProfilePictureURL(candidateProfilePictureURL);
        })
        setLoading(false);
        setUploadedProfilePicture(undefined);
    }

    return (
        <div style={styles}>
            <div style={styles.imageWrapper}>
                <div style={styles.imageHolder}>
                    <img style={styles.candidateImage} src={profilePictureURL} alt="profile picture" />
                    <Button style={styles.cameraIconWrapper} onClick={openProfilePictureFileSelect}>
                        <CameraIcon style={styles.cameraIcon} />
                    </Button>
                    <input type="file" name="profile-picture" id="profile-picture" onChange={handleProfilePictureFileChange} style={styles.fileInput}/>
                </div>
                <StoreButton styles={styles.storeButton} />
                <Button style={styles.settingsButton} onClick={handleSettingsClick}>{"Settings"}</Button>
                {!candidate.isPremium && <div>                
                    <Button style={styles.premiumButton} onClick={tryPremium}>
                        <Crown style={styles.premiumButton.icon} />
                        {isMobile ? "Premium" : "Try Premium"}
                    </Button>
                </div>}
            </div>
            <div style={styles.detailWrapper}>                
                <h4 style={styles.name}>
                    {candidate.forename + " " + candidate.surname}
                    {candidate.isPremium && <div 
                        onMouseEnter={() => setDisplayPremiumMessage(true)} 
                        onMouseLeave={() => setDisplayPremiumMessage(false)}
                        onClick={() => setDisplayPremiumMessage(!displayPremiumMessage)}
                        style={styles.crownWrapper}
                    >
                        <Crown style={styles.crownIcon} />
                    </div>}                    
                </h4>
                {displayPremiumMessage && <div style={styles.premiumMessage}>
                    <div style={styles.premiumMessage.pointer}></div>
                    {premiumMessage}
                </div>}
                <p style={styles.jobTitle}>{candidate.recentJobTitle}</p>
                {candidate.location !== null && candidate.location !== "" && 
                <p style={styles.location}>
                    <Location style={styles.icon} />{candidate.location}                    
                </p>}
                {candidate.lastUpdatedDate !== null &&
                <p style={styles.lastUpdate}>
                    <Calendar style={styles.icon} />
                    {"Last Update"}&nbsp;<span style={styles.bold}>{getFormatedDate(candidate.lastUpdatedDate)}</span>
                </p>}
                {candidate.personalSummary !== null && candidate.personalSummary !== "" && 
                <>
                    <h5 style={styles.summaryTitle}>{isMobile ? "Personal Summary" : "Summary"}</h5>
                    <p style={styles.summary}>{isFullSummary ? <>{candidate.personalSummary}</>
                    : <>
                        {candidate.personalSummary.slice(0, summaryLimit) + " ..."}
                        <Button style={styles.readMore} onClick={() => setIsFullSummary(true)}>{"Read More"}</Button>
                    </>}</p>
                </>}
                <hr style={styles.line} />
                {/* This is loading only mobile platform */}
                {props.completedPercentage !== 100 && <LevelIndicator showMyinformation={handleShowMyInformation} styles={styles.mobileProfileLevelIndicator} percentage={props.completedPercentage}/>}
            </div>
            <div style={styles.shareWrapper}>                                    
                <div style={styles.shareText}>{Texts.shareCV}</div>
                <Switch styles={styles.shareCVSwitch} checked={shareCVWithEmployers} handleClick={() => {setShareCVWithEmployers(!shareCVWithEmployers)}}/>                
            </div>
            {/* This is loading only mobile platform */}
            {/* Render it based on isMobile value to improve performance since the usage of WebViewer in the CVPreviewer component */}
            {isMobile && <div style={styles.cvExpandWrapper}>
                <div style={{ ...styles.cvExpand, ...(cvExpand && styles.cvExpand.expanded) }} onClick={() => setCVExpand((prev) => !prev)}>
                    {"My CV"}<DropDownIcon style={{ ...styles.cvExpandIcon, ...(cvExpand && styles.cvExpandIcon.expanded) }} />
                </div>
                {cvExpand && <Transition>
                    <div style={styles.cvWrapper}>
                        <CVPreviewer styles={styles.cvPreview} cvFile={props.cvFile} cvFileName={candidate.cvFileName}/>
                        <div style={styles.cvWrapper.right}>
                            <p style={styles.uploadedCVName}>{candidate.cvFileName}</p>
                            <div style={styles.viewCV.link} onClick={() => setOpenCVPopup(true)}>
                                View CV <ExternalLink style={styles.viewCV.icon}/>
                            </div>
                            <Dropzone onDrop={acceptedFiles => props.handleCVFileChange(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ style: styles.uploadCV })} className="center-content">
                                        <input {...getInputProps()} />
                                        <div style={styles.uploadCVTexts}>
                                            <div style={styles.uploadIconWrapper}><FileUpload style={styles.uploadIcon} /></div>
                                            <div>{Texts.addNewCV}</div>
                                            <label style={styles.uploadCVMaxFileSize}>{Texts.fileSize}</label>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                </Transition>}
            </div>}
            <PopUpWindow styles={styles.viewCV.popup} open={openCVPopup} toggle={() => setOpenCVPopup(!openCVPopup)} closeCircle={true}>
                <DocumentViewer document={getCandidateCVURL(candidate.cvTranslationText)} styles={styles.viewCV.documentViewer}/>
            </PopUpWindow>
            <PopUpWindow open={uploadedProfilePicture != undefined} styles={styles.uploadImagePopup} toggle={removeSelectedProfilePicture} >
                <img style={styles.uploadedImage} src={profilePictureURL} alt="Profile Picture" />
                <Button style={styles.uploadImageButton} disabled={loading} onClick={handleAddProfilePicture}>{"Add Profile Picture"}</Button>
            </PopUpWindow>
        </div>
    )
}