import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const loginRegister = {
    mobile: {
        mainWrapper: {
            display: "flex"            
        },
        leftWrapper: {
            display: "none",
            width: "71.14vw",            
            backgroundColor: Color.Blue
        },
        rightWrapper: {
            display: "none",
            width: "28.85vw",
            marginTop: "-23vh",
            backgroundColor: Color.White,
            zIndex: "1"
        },
        candidatesSvg: {
            height: "26.58vw",
            width: "42.38vw",
            backgroundSize: "42.38vw 26.58vw",
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/candidates.svg)",
            backgroundRepeat: "no-repeat",            
            backgroundPosition: "left",            
            marginTop: "6.51vw",
            marginLeft: "9.01vw",
            transition: "all 0.2s ease-in-out",
            position: "relative" as "relative",
            register: {
                height: "26.58vw",
                width: "34.38vw",
                backgroundSize: "34.38vw 26.58vw",
                marginLeft: "4.6vw",
                zIndex: "2"
            }
        },
        buildingSvg: {
            position: "relative",
            height: "20.64vw",
            width: "67.70vw",
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/login_register_building.svg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPosition: "bottom",
            zIndex: 1,
            marginTop: "-5.3125vw"
        },
        loginForm: {
            width: "90.34vw",
            backgroundColor: Color.White,
            padding: "0 4.83vw",
            marginTop: "14.49vw",
            zIndex: "1"
        },
        registerForm: {
            width: "90.34vw",
            backgroundColor: Color.White,
            padding: "0 4.83vw",
            marginTop: "14.49vw",
            zIndex: "1"
        },
        loginPassword: {
            marginTop: "8.69vw"
        },
        callbackMessage: {
            height: "auto",
            padding: "4.83vw 3.38vw",
            margin: "3.24vh 0 0 0",
            borderRadius: "2.41vw",
            border: "0.24vw solid " + Color.HarvestGold,
            backgroundColor: Color.LightGold,
            fontFamily: Font.Poppins,
            fontSize: "3.86vw",
            fontWeight: 500,
            color: Color.HarvestGold,
            textAlign: "center"
        },
        passwordForm: {
            width: "90.34vw",
            backgroundColor: Color.White, 
            padding: "0 4.83vw",
            zIndex: "1",
            reset: {
               margin: "24.15vw 0 13.04vw"
            },
            lost: {
                margin: "24.15vw 0 51.69vw"
            }
        },
        accordionButtonsContainer: {
            borderBottom: "2px solid " + Color.DeepBlack,
            display: "flex",
            justifyContent: "center"
        },
        accordionButton: {
            height: "9.42vw",
            borderTop: "2px solid " + Color.DarkAsh,
            borderBottom: "none",
            fontFamily: Font.Default,
            fontWeight: "bold",
            fontSize: "4.83vw",
            backgroundColor: Color.White,
            color: Color.SharpAsh,
            textAlign: "center" as "center",
            cursor: "pointer",
            letterSpacing: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            WebkitTapHighlightColor : "transparent",
            left: {
                width: "22vw",
                borderRadius: "5px 0px 0px 0px",
                borderLeft: "2px solid " + Color.DarkAsh,
                borderRight: "none"
            },
            right: {
                width: "29vw",
                borderRadius: "0px 5px 0px 0px",
                borderLeft: "none",
                borderRight: "2px solid " + Color.DarkAsh
            },
            selected: {
                border: "2px solid " + Color.DeepBlack,
                color: Color.White,
                backgroundColor: Color.DeepBlack,
                fontWeight: "500"
            }
        },
        title: {
            color: Color.LightBlack,
            fontSize: "6.03vw",
            margin: "0 0 0 3.86vw"            
        },
        subTitle: {
            color: Color.SharpAsh,
            fontSize: "4.83vw",
            fontWeight: "bold" as "bold",
            margin: "6.03vw 0 6.03vw 3.86vw"
        },
        boldTitle: {
            color: Color.DeepBlack,
            fontSize: "4.83vw",
            fontWeight: "bold",
            margin: "7.25vw 0 6.04vw 3.62vw"
        },
        hyperLink: {
            color: Color.DeepBlack,
            fontWeight: "bold",
            cursor: "pointer",
            marginLeft: "2.90vw",
            WebkitTapHighlightColor : "transparent"
        },
        normalText: {
            margin: "4.83vw 0 6.28vw 0",
            fontSize: "3.86vw",
            fontWeight: 500,
            color: Color.SharpAsh
        },
        row: {
            display: "block"
        },
        selectGender: {
            wrapper: {
                width: "-webkit-fill-available",
                borderRadius: "2.41vw",
                display: "flex",
                alignItems: "center",
                color: Color.DeepBlack,             
                fontSize: "3.86vw",
                marginTop: "1.5vh",
                justifyContent: "space-around",
                position: "relative",
                invalid: {
                    color: Color.Red
                }
            },
            label: {
                fontFamily: Font.Poppins
            },
            radio: {
                wrapper: {
                    display: "flex",
                    alignItems: "center"                    
                },
                label: {
                    fontFamily: Font.Poppins
                }
            },
            iconButton: {
                WebkitTapHighlightColor: "transparent",
                hover: {}
            },
            icon: {
                width: "4.17vw",
                height: "4.17vw",
                fill: Color.SharpAsh
            },        
            inputError: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                fontSize: "4.44vw",
                textAlign: "center",
                icon: {
                    width: "4.17vw",
                    height: "4.17vw"                    
                }      
            }
        },
        resetNow: {
            border: "2px solid " + Color.Blue,
            color: Color.Blue,
            marginLeft: "2%",
            cursor: "pointer",
            textTransform: "uppercase",
            borderRadius: "1.2vw",
            fontFamily: Font.Default,
            fontWeight: "bold" as "bold",
            fontSize: "2.89vw",
            background: "transparent",
            WebkitTapHighlightColor: "transparent",
            width: "30.19vw",
            height: "6.04vw"
        },
        registerNowWrapper: {
            borderBottom: "1px solid " + Color.MiddleAsh,
            padding: "1.67vh 0 3.348vh 0",
            textAlign: "center" as "center",
            color: Color.SharpAsh,
            fontSize: "3.86vw"
        },
        loginNowWrapper: {
            fontSize: "3.86vw",            
            color: Color.SharpAsh,
            margin: "3.62vw 0 7.25vw 0"
        },
        submitButton: {
            border: "none",
            backgroundColor: Color.Green,
            color: Color.White,
            width: "100%",
            height: "12.08vw",
            borderRadius: "2.41vw",
            fontSize: "4.34vw",
            fontFamily: Font.Default,
            fontWeight: "bold" as "bold",
            textTransform: "uppercase",
            boxShadow: "5px 5px 50px #00000027",
            cursor: "pointer"
        },
        cvUpload: {
            transition: {
                width: "100%",
                zIndex: "3"
            },
            popup:{
                justifyContent: "center",
                container: {
                    width: "90.33vw",
                    height: "166.67vw",
                    borderRadius: "2.41vw",
                    padding: "2.41vw"
                }
            },
            document: {
                height: "92%"
            },
            dropzone: {
                height: "100%"
            },
            uploadCVTexts: {
                border: "1px dashed " + Color.DarkGray,
                padding: "1%",
                height: "98%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                cursor: "pointer"
            },
            buttonWrapper: {
                display: "flex",
                flexDirection: "row",                  
                justifyContent: "space-between",              
                marginTop: "2%"
            },
            button: {
                border: "none",
                width: "49%",
                height: "12.08vw",
                borderRadius: "2.41vw",
                fontSize: "4.34vw",
                fontWeight: "bold" as "bold",
                textTransform: "uppercase",
                cursor: "pointer",
                submit: {
                    color: Color.White,
                    backgroundColor: Color.Blue
                },
                cancel: {
                    color: Color.SharpAsh,
                    backgroundColor: Color.LightAsh
                }
            }
        },
        fileWarning: {
            fontSize: "2.65vw",
            color: Color.SharpAsh,
            margin: "2.41vw 0 0 0",
            bold: {
                fontWeight: "500"
            }
        },
        sharpText: {
            fontSize: "3.14vw",            
            color: Color.SharpAsh,
            margin: "2%",
            black: {                
                fontWeight: "500",
                color: Color.DeepBlack,
                cursor: "pointer",
                textDecoration: "none"
            },
            bold: {                
                fontWeight: "bold",
                color: Color.DeepBlack,
                cursor: "pointer"
            }
        },
        policyWrapper: {
            fontSize: "13px",
            color: Color.SharpAsh,
            marginTop: "6.04vw"
        },
        policyLabel: {
            marginLeft: "1.21vw"
        },
        jobsByEmail: {
            marginTop: "2.41vw"
        },
        registerWrapper: {
            width: "100%",
            margin: "9.66vw 0 0 0",
            textAlign: "center" as "center"
        },
        social: {
            display: "block",
            width: "100%",
            borderTop: "2px solid " + Color.LightAsh,
            alignItems: "center" as "center",            
            iconWrapper: {
                width: "10.38vw",
                height: "10.38vw",
                borderRadius: "50%",
                border: "1px solid " + Color.DarkAsh,
                margin: "0 1.81vw",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "transparent"
            },
            icon: {
                width: "5.19vw",
                height: "5.19vw",
                facebook: {
                    fill: Color.BlueYonder
                },
                linkedIn: {
                    fill: Color.HonoluluBlue
                }
            },
            loginUsing: {
                wrapper: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                },
                text: {
                    margin: "3.34vh 0 2.23vh 0",
                    fontFamily: Font.Default,
                    fontSize: "3.86vw",
                    fontWeight: 500,
                    color: Color.DeepBlack,
                    textAlign: "center" as "center"
                }
            },
            registerUsing: {
                display: "flex",
                justifyContent: "center" as "center",
                marginLeft: "unset",
                alignItems: "center",
                text: {                                        
                    fontSize: "3.86vw",
                    color: Color.DeepBlack,
                    textAlign: "center" as "center"
                }
            }
        },
        resetButton: {
            marginTop: "8.45vw"
        }
    },
    desktop: {
        mainWrapper: {
            marginBottom: "-7.8vw"
        },
        leftWrapper: {
            display: "block"
        },
        rightWrapper: {
            display: "block"
        },
        accordionButtonsContainer: {
            borderBottom: "0.15vw solid " + Color.DeepBlack
        },
        loginForm: {
            position: "absolute" as "absolute",
            width: "29.89vw",
            right: "7.29vw",
            top: "12.71vw",
            borderRadius: "0.52vw",
            border: `2px solid ${Color.LightAsh}`,
            padding: "2.08vw 2.60vw",
            marginTop: 0
        },
        registerForm: {
            position: "absolute" as "absolute",
            width: "42.5vw",
            right: "7.29vw",
            top: "9.375vw",
            borderRadius: "0.52vw",
            border: `2px solid ${Color.LightAsh}`,
            padding: "2.08vw 2.6vw",
            marginTop: 0,
            messageEnabled: {
                top: "8.12vw"
            }
        },
        loginPassword: {
            marginTop: "0.78vw"
        },
        registerLeftInput: {
            marginLeft: "0.83vw"
        },
        callbackMessage: {
            padding: "0.5vw 4.40vw",
            margin: "2.68vh 0 0 0",
            borderRadius: "0.52vw",
            border: "0.05vw solid " + Color.HarvestGold,
            fontSize: "0.94vw"
        },
        selectGender: {
            wrapper: {
                width: "41.20vw",
                borderRadius: "0.52vw",
                border: "2px solid " + Color.LightAsh,
                fontSize: "0.94vw",
                padding: "0 0.7vw",                
                invalid: {
                    border: "1px solid " + Color.Red,
                    background: Color.CoralRed
                }
            },
            radio: {                
                input: {
                    marginRight: "0.52vw"
                }
            },
            icon: {
                width: "1.05vw",
                right: "1.75vw"
            },
            inputError: {
                fontSize: "1vw",
                icon: {
                    width: "1.05vw",
                    height: "1.05vw",
                    marginLeft: "1.04vw"
                }
            }
        },
        passwordForm: {
            position: "absolute" as "absolute",
            right: "6.5%",
            borderRadius: "0.52vw",
            border: `2px solid ${Color.LightAsh}`,
            padding: "2.6vw",
            margin: 0,
            reset: {
                width: "29.78vw",
                height: "19.90vw",
                top: "37.56%",
                margin: "unset"
            },
            lost: {
                width: "29.75vw",
                height: "15.75vw",
                top: "19.53vw",
                margin: "unset"
            }
        },
        accordionButton: {
            height: "2.96vw",
            fontSize: "1.3vw",
            fontWeight: "500",
            left: {
                width: "7.29vw",
                borderRadius: "0.52vw 0px 0px 0px"
            },
            right: {
                width: "9.42vw",
                borderRadius: "0px 0.52vw 0px 0px"
            },
            selected: {
                fontSize: "1.56vw",
                fontWeight: "bold"
            }
        },        
        title: {
            fontSize: "1.56vw",
            marginLeft: "0.5vw"
        },
        subTitle: {
            fontSize: "1vw",
            margin: "1.41vw 0 0.78vw 0.5vw"
        },
        boldTitle: {            
            fontSize: "0.94vw",            
            margin: "1.56vw 0 0.78vw 0.52vw",
            register: {
                margin: "1.56vw 0 0 0.52vw"
            }
        },
        hyperLink: {
            marginLeft: "0.83vw"            
        },
        normalText: {
            fontSize: "0.93vw",
            margin: "0.78vw 0 1.56vw 0.52vw",
            color: Color.DeepBlack
        },
        row: {
            display: "flex"
        },
        fileWarning: {
            fontSize: "0.83vw",            
            margin: "0.52vw 0 0 0.52vw"
        },
        sharpText: {
            fontSize: "0.83vw",
            marginTop: "2.77vh"
        },
        social: {
            display: "flex",
            margin: "1.56vw 0 0 2.60vw",
            borderLeft: "2px solid " + Color.LightAsh,
            borderTop: "none",
            width: "54%",
            height: "3.64vw",            
            loginUsing: {
                text: {
                    margin: "2.77vh 0 1.85vh 0",
                    fontSize: "0.93vw"
                }
            },
            registerUsing: {
                justifyContent: "unset",
                marginLeft: "1.30vw",
                text: {
                    fontSize: "0.93vw",
                    marginLeft: "5%"
                }
            },
            iconWrapper: {
                width: "2.23vw",
                height: "2.23vw",
                margin: "0 0.625vw"
            },
            icon: {
                width: "1.13vw",
                height: "1.13vw"
            }
        },
        policyWrapper: {
            display: "flex",
            fontSize: "0.83vw",            
            margin: "1.56vw 0 0 0.52vw"
        },
        policyLabel: {
            marginLeft: "0.52vw"
        },
        policyLabelError:{
            color : "red"
        },
        jobsByEmail: {
            margin: "0 0 0 2.97vw"
        },
        registerWrapper: {
            width: "46%",
            margin: "1.56vw 0 0 0"
        },
        resetButton: {
            marginTop: "1.56vw"
        },
        resetNow: {
            border: "3px solid " + Color.Blue,
            width: "10.15vw",
            height: "1.82vw",
            fontSize: "0.78vw",
            borderRadius: "0.26vw"            
        },
        submitButton: {
            height: "3.64vw",
            borderRadius: "0.52vw",
            fontSize: "1.04vw"        
        },
        registerButton: {
            width: "19.53vw",
            float: "left"
        },
        registerNowWrapper: {
            fontSize: "0.93vw",
            padding: "1.30vw 0 1.56vw 0",
            color: Color.SharpAsh
        },
        loginNowWrapper: {
            fontSize: "0.93vw",            
            margin: "1.30vw 0 0 0"
        },
        cvUpload: {
            cursor: "pointer",
            transition: {
                width: "unset"
            },
            popup:{
                container: {                    
                    width: "30.99vw",
                    height: "43.85vw",                    
                    borderRadius: "0.52vw",
                    padding: "0.83vw"
                },
                closeButton: {                    
                    width: "0.94vw",
                    height: "0.94vw",
                    top: "0.21vw",
                    right: "0.21vw"
                }
            },
            document: {
                height: "91%"
            },
            button: {
                height: "6.48vh",
                borderRadius: "0.52vw",
                fontSize: "1.04vw"
            }
        }
    }
}