import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonTexts as Texts } from '../../helpers/LayoutTexts';
import { getGovernmentJobLogoURL, getSEOKeyword } from '../../helpers/Common';

interface Props {
    style: any;
    organizationId: number;
    organizationName: string;
    hasLogo: boolean;
    logoUri: string;
    jobId?: number;
    isGovernmentJob?: boolean;
};

export const Logo: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [hover,setHover] = useState(false);
    const logoUrl = props.isGovernmentJob ? getGovernmentJobLogoURL(props.jobId) : (props.hasLogo && props.logoUri) ? props.logoUri : Texts.defaultCompanyLogo;

    const handleLogoClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        navigate("/organization/" + props.organizationId + "/" + getSEOKeyword(props.organizationName));
    }

    return (        
        <img src={logoUrl}  
            style={{...props.style, ...(hover ? props.style.hover : props.style.normal)}} 
            onMouseEnter={()=>setHover(true)} 
            onMouseLeave={()=>setHover(false)}
            onClick={handleLogoClick} className={"responseive-square"}
            alt="company logo">
        </img>
    );
}